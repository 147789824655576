<template>
  <div>
    <img :src="link.siteImage" class="ana-site-image" alt="" />
  </div>
</template>

<script>
export default {
  name: "SiteImage",
  props: {
    link: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style scoped>
.ana-site-image {
  height: 170px;
  width: 100%;
  object-fit: cover;
  border: 1px solid var(--anaGrey2);
  border-radius: var(--anaRadius1);
}
</style>
