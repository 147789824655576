<template>
  <div class="py-3">
    <h3 class="ana_h5 text-muted">Top Days</h3>
    <div class="py-3">
      <bar-chart
        :chart-data="datacollection"
        :options="chartOptions"
      ></bar-chart>
    </div>
  </div>
</template>

<script>
import BarChart from "@/components/BarChart";
export default {
  name: "TopDays",
  components: {
    BarChart,
  },
  props: {
    link: {
      type: Object,
      default: () => {},
    },
    datacollection: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        tickColor: "#000000",
        scales: {
          xAxes: [
            {
              gridLines: {
                color: "rgba(0, 0, 0, 0)",
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                color: "rgba(0, 0, 0, 0)",
              },
            },
          ],
          y: {
            beginAtZero: true,
            ticks: {
              stepSize: 1.0,
            },
          },
        },
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
      },
    };
  },
};
</script>

<style></style>
