<template>
  <div class="row" v-if="link.clicks > 0">
    <div class="col-6 col-lg-3">
      <div class="summary-card">
        <div class="d-flex w-100 align-items-center justify-content-between">
          <div class="summary-card__title">All Clicks</div>
          <div class="summary-card__title">
            <i class="bi bi-hand-index"></i>
          </div>
        </div>
        <div class="summary-card__item w-100">
          {{ link.clicks }}
        </div>
      </div>
    </div>
    <div class="col-6 col-lg-3">
      <div class="summary-card" v-if="topDevice">
        <div class="d-flex w-100 align-items-center justify-content-between">
          <div class="summary-card__title">Unique Clicks</div>
          <div class="summary-card__title">
            <i class="bi bi-person-check"></i>
          </div>
        </div>
        <div class="summary-card__item w-100">
          {{ link.visits.uniqueVisitorsCount }}
        </div>
      </div>
    </div>
    <div class="col-6 col-lg-3">
      <div class="summary-card" v-if="topBrowser">
        <div class="d-flex w-100 align-items-center justify-content-between">
          <div class="summary-card__title">Top Browser</div>
          <div class="summary-card__title">
            <i :class="topBrowser.icon"></i>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="small text-muted text-capitalize">
            {{ topBrowser.topItem }}
          </div>
          <div class="summary-card__item">
            {{ topBrowser.visits }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 col-lg-3">
      <div class="summary-card" v-if="topDevice">
        <div class="d-flex w-100 align-items-center justify-content-between">
          <div class="summary-card__title">Top Device</div>
          <div class="summary-card__title">
            <i :class="topDevice.icon"></i>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="small text-muted text-capitalize">
            {{ topDevice.topItem }}
          </div>
          <div class="summary-card__item">
            {{ topDevice.visits }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PerformanceSummary",
  props: {
    link: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      topBrowser: null,
      topDevice: null,
      topOS: null,
    };
  },
  mounted() {
    this.getTopBrowser();
    this.getTopDevice();
    this.getTopOS();
  },
  methods: {
    getTopItem(data) {
      let maxVisits = 0;
      let topItem = null;

      for (const item in data) {
        if (data[item].visits > maxVisits) {
          maxVisits = data[item].visits;
          topItem = item;
        }
      }

      if (topItem) {
        const { visits, icon } = data[topItem];
        return { topItem, visits, icon };
      } else {
        return null;
      }
    },
    getTopBrowser() {
      const link = this.link;
      const browsers = {
        chrome: { visits: link.visits.browsers.chrome, icon: "fab fa-chrome" },
        firefox: {
          visits: link.visits.browsers.firefox,
          icon: "fab fa-firefox",
        },
        opera: { visits: link.visits.browsers.opera, icon: "fab fa-opera" },
        safari: { visits: link.visits.browsers.safari, icon: "fab fa-safari" },
        edge: { visits: link.visits.browsers.edge, icon: "fab fa-edge" },
      };

      this.topBrowser = this.getTopItem(browsers);
    },

    getTopDevice() {
      const link = this.link;
      const devices = {
        mobile: { visits: link.visits.devices.mobile, icon: "bi bi-phone" },
        tablet: { visits: link.visits.devices.tab, icon: "bi bi-tablet" },
        computer: { visits: link.visits.devices.pc, icon: "bi bi-laptop" },
        smartTV: { visits: link.visits.devices.smartTV, icon: "bi bi-tv" },
      };

      this.topDevice = this.getTopItem(devices);
    },

    getTopOS() {
      const link = this.link;
      const OS = {
        android: {
          visits: link.visits.operatingSystems.android,
          icon: "fab fa-android",
        },
        ios: { visits: link.visits.operatingSystems.ios, icon: "fab fa-apple" },
        windows: {
          visits: link.visits.operatingSystems.windows,
          icon: "fab fa-windows",
        },
        linux: {
          visits: link.visits.operatingSystems.linux,
          icon: "fab fa-linux",
        },
      };

      this.topOS = this.getTopItem(OS);
    },
  },
};
</script>

<style>
.summary-card {
  min-height: 100px;
  width: 100%;
  border: 1px solid var(--anaGrey2);
  border-radius: var(--anaRadius2);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  margin: 1rem auto;
}
.summary-card .summary-card__item {
  font-size: 2.5rem;
  font-weight: var(--fontBold);
}
</style>
