<template>
  <div class="link-actions">
    <div class="my-2 text-center">
      <div>
        <a :href="link.shortURL" target="_blank" class="ana-link">{{
          link.shortURL
        }}</a>
      </div>
    </div>
    <button
      @click="copyURL(link.shortURL)"
      class="action-btn"
      ref="URLtoCopy"
      v-tooltip.top-center="'Copy to clipboard'"
    >
      Copy
    </button>
    <router-link
      class="action-btn mt-1"
      :to="`/dash/link/edit/${link._id}/${link.slug}`"
    >
      Edit</router-link
    >
    <router-link
      class="action-btn mt-1"
      :to="`/dash/link/redirect/${link._id}/${link.slug}`"
    >
      Rediret</router-link
    >
    <button @click="soon()" class="action-btn mt-1 disabled">QR</button>
    <button @click="confirmDelete(link._id)" class="action-btn mt-1 danger">
      Delete
    </button>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "LinkActions",
  props: {
    link: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    deleteLink(linkID) {
      axios.put(`my-links/delete/${linkID}`, linkID).then((res) => {
        this.$toast(`Your Link has been deleted successfully`, {
          timeout: 3000,
          type: "success",
        });
        this.$router.push("/dash/YourLinks");
      });
    },
    confirmDelete(id) {
      this.$swal({
        title: "Are you sure you want to delete this?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Yes, Delete",
        denyButtonText: `Nevermind`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteLink(id);
        } else if (result.isDenied) {
        }
      });
    },
    copyURL(url) {
      navigator.clipboard.writeText(url);
      this.$toast(`Copied to clipboard`, {
        timeout: 2000,
        type: "info",
      });
    },
    soon() {
      this.$toast(`Feature coming soon`, {
        timeout: 2000,
        type: "info",
      });
    },
  },
};
</script>

<style>
.link-actions {
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  background: var(--uiDarkBlue);
  border-radius: 1rem;
  padding: 1rem 1.5rem;
  z-index: 5;
  border: 1px solid var(--secondaryColor);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 768px) {
  .link-actions {
    width: 90%;
  }
}
</style>
