<template>
  <div>
    <a
      href="javascript:void(0)"
      class="text-decoration-none ana_h3 link-holder__action text-muted mb-0"
      @click="makeFavourite(linkData)"
    >
      <div class="fav-loader" v-if="isLoading"></div>
      <i
        v-if="!isLoading"
        class="bi"
        :class="[
          linkData.isFavourite ? 'bi-heart-fill text--primary' : 'bi-heart',
          { 'animate-heart': floatingHeart },
        ]"
      ></i>
    </a>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "FavouriteToggle",
  props: {
    link: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      linkData: this.link,
      isLoading: false,
      floatingHeart: false,
    };
  },
  methods: {
    async makeFavourite(link) {
      if (this.isLoading) {
        return;
      }
      const id = link._id;
      this.isLoading = true;
      try {
        const { data } = await axios.put(`my-links/favourite/${id}`, {
          isFavourite: !link.isFavourite,
        });
        this.linkData.isFavourite = data.link.isFavourite;
        this.floatingHeart = true;
        setTimeout(() => {
          this.floatingHeart = false;
        }, 3000);
        this.$emit("favouriteUpdated", data.link);
      } catch (error) {
        console.log(error);
        this.$toast.error("Error updating link. Please try again");
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
.fav-loader {
  border: 1px solid transparent;
  border-top: 1px solid #f3f3f3;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.bi-heart-fill.animate-heart {
  display: block;
  animation: floatHeart 3s ease;
}

@keyframes floatHeart {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(-20px);
    opacity: 0.7;
  }
  100% {
    transform: translateY(-40px);
    opacity: 0;
  }
}
</style>
