<template>
  <div class="py-3">
    <h3 class="ana_h5 text-muted">By OS</h3>
    <div class="row align-items-start">
      <div class="col-lg-3 col-6">
        <p class="ana_parapgraph py-3">
          <i class="fab mr-2 fa-android" style="color: #3ddc84"></i>
          Android: <strong>{{ link.visits.operatingSystems.android }}</strong>
        </p>
      </div>
      <div class="col-lg-3 col-6">
        <p class="ana_parapgraph py-3">
          <i class="fab mr-2 fa-apple" style="color: #a2aaad"></i>
          iOS: <strong> {{ link.visits.operatingSystems.ios }}</strong>
        </p>
      </div>
      <div class="col-lg-3 col-6">
        <p class="ana_parapgraph py-3">
          <i class="fab mr-2 fa-windows" style="color: #00a1f1"></i>
          Windows: <strong>{{ link.visits.operatingSystems.windows }}</strong>
        </p>
      </div>
      <div class="col-lg-3 col-6 d-none">
        <p class="ana_parapgraph py-3">
          <i class="fab mr-2 fa-apple" style="color: #a2aaad"></i>
          Mac: {{ link.visits.operatingSystems.mac }}
        </p>
      </div>
      <div class="col-lg-3 col-6">
        <p class="ana_parapgraph py-3">
          <i class="fab mr-2 fa-ubuntu" style="color: #e95420"></i>
          Linux: <strong>{{ link.visits.operatingSystems.linux }}</strong>
        </p>
      </div>
    </div>
    <div class="dropdown-divider"></div>
  </div>
</template>

<script>
export default {
  name: "OSAnalytics",
  props: {
    link: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style></style>
