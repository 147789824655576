<template>
  <div class="py-2">
    <!-- <div>
      <small class="text-muted">Share link</small>
    </div> -->
    <ShareNetwork
      v-for="network in networks"
      :network="network.network"
      :key="network.network"
      :style="{ backgroundColor: network.color }"
      :url="`${link.shortURL}`"
      :title="`${link.title}`"
      :description="`${link.siteDescription}`"
      class="social-icon"
    >
      <div>
        <i :class="network.icon"></i>
      </div>
    </ShareNetwork>
  </div>
</template>

<script>
export default {
  name: "SocialShare",
  props: {
    link: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      networks: [
        {
          network: "email",
          name: "Email",
          icon: "fas fa-envelope",
          color: "#333333",
        },
        {
          network: "facebook",
          name: "Facebook",
          icon: "fab fa-facebook-f",
          color: "#1877f2",
        },
        {
          network: "linkedin",
          name: "LinkedIn",
          icon: "fab fa-linkedin",
          color: "#007bb5",
        },
        {
          network: "messenger",
          name: "Messenger",
          icon: "fab fa-facebook-messenger",
          color: "#0084ff",
        },
        {
          network: "sms",
          name: "SMS",
          icon: "fa  fa-commenting",
          color: "#333333",
        },
        {
          network: "telegram",
          name: "Telegram",
          icon: "fab fa-telegram",
          color: "#0088cc",
        },
        {
          network: "twitter",
          name: "Twitter",
          icon: "fab fa-twitter",
          color: "#1da1f2",
        },
        {
          network: "whatsapp",
          name: "Whatsapp",
          icon: "fab fa-whatsapp",
          color: "#25d366",
        },
      ],
    };
  },
};
</script>

<style>
.social-icon:hover,
.social-icon {
  height: 30px;
  width: 30px;
  padding: 1rem;
  border-radius: 100%;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 0.75rem;
  margin: 1rem 0.5rem 0 0;
  transition: 0.2s ease-in-out;
  transform: scale(0.9);
}

.social-icon:hover {
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.08);
  transform: scale(1);
}
</style>
