<template>
  <div class="my-2">
    <small class="text-muted d-block mt-4 mb-1 ana_paragraph--small"
      >Created on {{ link.date | extractMonth }}</small
    >
    <div class="ana_paragraph--small text-muted my-2">
      <a
        :href="link.longURL"
        target="_blank"
        class="ana_paragraph ana-text--medium ana_text-dark mb-0 wordwrap"
        >{{ link.longURL }}</a
      >
    </div>
    <div class="">
      <h3 class="ana_h3 ana_text-dark">{{ link.title }}</h3>
      <p class="ana_paragraph ana_text-grey mb-0">
        {{ link.siteDescription }}
      </p>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
export default {
  name: "SiteDetails",
  props: {
    link: {
      type: Object,
      default: () => {},
    },
  },
  filters: {
    moment: (date) => {
      dayjs.extend(localizedFormat);
      if (date == "No visits yet") {
        return "No visits yet";
      } else {
        return dayjs(date).format("DD MMM YYYY - LT");
      }
    },
    extractMonth: (date) => {
      dayjs.extend(localizedFormat);
      if (date == "No visits yet") {
        return "No visits yet";
      } else {
        return dayjs(date).format("DD MMM YYYY");
      }
    },
  },
};
</script>

<style></style>
