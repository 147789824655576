<template>
  <div>
    <div class="app-wrapper">
      <div class="container py-5">
        <div class="col-lg-10 mx-auto p-0 p-0">
          <div
            class="mt-3 mb-3 d-flex align-items-center justify-content-center py-5"
            v-if="isLoading"
          >
            <div class="loader"></div>
          </div>
          <div v-if="!isLoading">
            <LinkActions :link="link" />
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <span class="cancel" @click="$router.go(-1)">
                  <i class="ti-arrow-left"></i>
                </span>
                <span class="small ml-2 ana_text-dark">Go back</span>
              </div>
              <FavouriteToggle :link="link" />
            </div>
            <div class="dropdown-divider mb-3 mt-4"></div>
            <div class="row align-items-start">
              <div class="col-lg-12">
                <SiteImage :link="link" />
              </div>
              <div class="col-lg-12">
                <SiteDetails :link="link" />
                <SocialShare :link="link" />
                <div class="dropdown-divider my-4"></div>
              </div>
              <div class="col-lg-12">
                <PerformanceSummary :link="link" v-if="link.clicks > 0" />
                <EmptyAnalytics v-else />
              </div>
            </div>
            <div class="dropdown-divider mb-5 mt-3"></div>
            <div v-if="link.clicks > 0">
              <div class="my-2">
                <h3 class="ana_h3 text-muted">Performance Report</h3>
              </div>
              <PlatformAnalytics :link="link" />
              <BrowserAnalytics :link="link" />
              <OSAnalytics :link="link" />
              <TopDays :datacollection="datacollection" />
              <div class="py-5">
                <div class="py-3">
                  <button
                    class="p-1 action-btn ana-btn__grey"
                    type="button"
                    @click="getURLData()"
                  >
                    Refresh Data
                  </button>
                </div>
                <p class="small text-muted font-weight-bold">
                  More Analytics coming soon...
                </p>
              </div>
            </div>
            <EmptyAnalytics v-else />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

import SiteImage from "@/components/dash/Link/SiteImage";
import PerformanceSummary from "@/components/dash/Link/PerformanceSummary";
import PlatformAnalytics from "@/components/dash/Link/PlatformAnalytics";
import BrowserAnalytics from "@/components/dash/Link/BrowserAnalytics";
import OSAnalytics from "@/components/dash/Link/OSAnalytics";
import TopDays from "@/components/dash/Link/TopDays";
import LinkActions from "@/components/dash/Link/LinkActions";
import SiteDetails from "@/components/dash/Link/SiteDetails";
import EmptyAnalytics from "@/components/dash/Link/EmptyAnalytics";
import SocialShare from "@/components/dash/Link/SocialShare";
import SiteUrls from "@/components/dash/Link/SiteUrls";
import FavouriteToggle from "@/components/dash/Link/FavouriteToggle";
export default {
  components: {
    SiteImage,
    PerformanceSummary,
    PlatformAnalytics,
    BrowserAnalytics,
    OSAnalytics,
    TopDays,
    LinkActions,
    SiteDetails,
    EmptyAnalytics,
    SocialShare,
    SiteUrls,
    FavouriteToggle,
  },
  name: "Link",
  metaInfo() {
    return {
      title: `${this.link.title || "fetching URL..."}`,
    };
  },
  data() {
    return {
      link: {},
      days: {},
      sortable: [],
      isLoading: false,
      datacollection: {},
    };
  },
  mounted() {
    this.getURLData();
  },
  methods: {
    async getURLData() {
      try {
        const linkID = this.$route.params.id;
        this.isLoading = true;
        const res = await axios.get(`my-links/${linkID}`);
        this.link = res.data.link;
        this.days = res.data.link.days;
        let chartLabels = [],
          chartData = [];
        for (const key in this.days) {
          chartLabels.push(key.substring(0, 3));
          chartData.push(this.days[key]);
        }
        this.datacollection = {
          labels: chartLabels,
          datasets: [
            {
              label: "Clicks",
              backgroundColor: "#19BB69",
              borderWidth: 0,
              data: chartData,
            },
          ],
        };
        this.isLoading = false;
      } catch (err) {
        console.log(err);
        this.$router.push("/dash/overview");
        this.isLoading = false;
        this.$toast(`An error occurred`, {
          timeout: 5000,
          type: "error",
        });
      }
    },
  },
};
</script>
