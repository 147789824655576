<template>
  <div class="py-3">
    <div class="row align-items-start">
      <div class="col-lg-12">
        <h3 class="ana_h5 text-muted">By Platform</h3>
      </div>
      <div class="col-lg-3 col-6 mt-2">
        <p class="ana_parapgraph py-3">
          <i class="bi mr-2 bi-phone" style="color: #ba6ffb"></i>
          Mobile: <strong>{{ link.visits.devices.mobile }}</strong>
        </p>
      </div>
      <div class="col-lg-3 col-6 mt-2">
        <p class="ana_parapgraph py-3">
          <i class="bi mr-2 bi-tablet" style="color: #fa882a"></i>
          Tablet: <strong>{{ link.visits.devices.tab }}</strong>
        </p>
      </div>
      <div class="col-lg-3 col-6 mt-2">
        <p class="ana_parapgraph py-3">
          <i class="bi mr-2 bi-laptop" style="color: #3d3dff"></i>
          Computer: <strong>{{ link.visits.devices.pc }}</strong>
        </p>
      </div>
      <div class="col-lg-3 col-6 mt-2">
        <p class="ana_parapgraph py-3">
          <i class="bi mr-2 bi-tv" style="color: #67f234"></i>
          SmartTV: <strong>{{ link.visits.devices.smartTV }}</strong>
        </p>
      </div>
    </div>
    <div class="dropdown-divider"></div>
  </div>
</template>

<script>
export default {
  name: "PlatformAnalytics",
  props: {
    link: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style></style>
