<template>
  <div class="py-3">
    <h3 class="ana_h5 text-muted">By Browser</h3>
    <div class="row align-items-start">
      <div class="col-lg-3 col-6 mt-2">
        <p class="ana_parapgraph py-3">
          <i class="fab fa-chrome mr-2" style="color: #d85642"></i>
          Chrome: <strong>{{ link.visits.browsers.chrome }}</strong>
        </p>
      </div>
      <div class="col-lg-3 col-6 mt-2">
        <p class="ana_parapgraph py-3">
          <i class="fab fa-firefox mr-2" style="color: #e66000"></i>
          Firefox: <strong>{{ link.visits.browsers.firefox }}</strong>
        </p>
      </div>
      <div class="col-lg-3 col-6 mt-2">
        <p class="ana_parapgraph py-3">
          <i class="fab fa-opera mr-2" style="color: #a11d2a"></i>
          Opera: <strong>{{ link.visits.browsers.opera }}</strong>
        </p>
      </div>
      <div class="col-lg-3 col-6 mt-2">
        <p class="ana_parapgraph py-3">
          <i class="fab mr-2 fa-safari" style="color: #006cff"></i>
          Safari: <strong>{{ link.visits.browsers.safari }}</strong>
        </p>
      </div>
      <div class="col-lg-3 col-6 mt-2">
        <p class="ana_parapgraph py-3">
          <i class="fab mr-2 fa-edge" style="color: #1479bd"></i>
          Edge: <strong>{{ link.visits.browsers.edge }}</strong>
        </p>
      </div>
    </div>
    <div class="dropdown-divider"></div>
  </div>
</template>

<script>
export default {
  name: "BrowserAnalytics",
  props: {
    link: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style></style>
