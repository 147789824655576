<template>
  <div class="border--grey">
    <div class="py-5 text-center col-lg-10 mx-auto p-0">
      <div class="ana_h1 text-muted text-center">
        <i class="bi bi-bar-chart-fill"></i>
      </div>
      <p class="ana_paragraph text-muted">
        Analytics will appear here when people start visiting your link
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmptyAnalytics",
};
</script>

<style></style>
