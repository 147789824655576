<template>
  <div class="my-4">
    <div>
      <a :href="link.shortURL" target="_blank" class="ana-link">{{
        link.shortURL
      }}</a>
    </div>
    <div>
      <small class="text-muted">Will redirect to</small>
    </div>
    <div>
      <a
        :href="link.longURL"
        target="_blank"
        class="ana_paragraph ana-text--medium ana_text-dark mb-0 wordwrap"
        >{{ link.longURL }}</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "SiteUrls",
  props: {
    link: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style></style>
